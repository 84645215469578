import {ReadableNode} from '../../../model/layout/readable-node';
import {LayoutPropertiesModel} from '../../../model/properties/layout-properties.model';
import {MetaControl} from '../../../model/meta/meta-control';
import {ReadingContext} from '../../reading-context';

export abstract class LayoutReader<T = ReadableNode | ReadableNode[]> {

    protected constructor(private readonly key: string,
                          public readonly properties: LayoutPropertiesModel,
                          protected readonly controlResolver: (key: string) => MetaControl,
                          private readonly _context: ReadingContext,
                          private readonly predecessor?: LayoutReader) {

    }

    protected get context(): ReadingContext {
        return this._context;
    }

    public read(key: string, value: object): ReadableNode | ReadableNode[] {
        if (this.isCapableToParse(key, value)) {
            return this.readValue(value);
        } else {
            return this.predecessor ? this.predecessor.read(key, value) : value;
        }
    }

    protected isCapableToParse(key: string, value: object) {
        return key === this.key;
    }

    protected abstract readValue(value: object): T;
}

import {fromJS, Map} from 'immutable';
import {JsonData} from '../../interpreter-utils';
import {PropertiesNode} from './properties-node';

export class ControlPropertiesModel extends PropertiesNode {
    public static GENERATED_ID_SUFFIX_KEY = 'generatedIdSuffix';

    public static createEmpty(id: string): ControlPropertiesModel {
        return new ControlPropertiesModel(id, Map());
    }

    public static copyWithNewId(
        newId: string,
        controlProperties: ControlPropertiesModel
    ): ControlPropertiesModel {
        return new ControlPropertiesModel(
            newId,
            controlProperties.generalProperties
        );
    }

    public static fromJS(
        controlId: string,
        jsObject: object
    ): ControlPropertiesModel {
        return new ControlPropertiesModel(controlId, fromJS(jsObject));
    }

    private _generatedId: string;

    constructor(id: string, public generalProperties: Map<string, JsonData>) {
        super(id);
    }

    get generatedId(): string {
        return this._generatedId;
    }

    set generatedId(value: string) {
        this._generatedId = value;
    }

    public toJS(): object {
        return this.generalProperties.set('controlId', this.id).toJS();
    }

    public get idSuffix(): string {
        return this.generalProperties &&
            this.generalProperties.has(
                ControlPropertiesModel.GENERATED_ID_SUFFIX_KEY
            )
            ? (this.generalProperties.get(
                  ControlPropertiesModel.GENERATED_ID_SUFFIX_KEY
              ) as string)
            : '';
    }
}

import {CellModel} from '../../../model/layout/cell-model';
import {LayoutPropertiesModel} from '../../../model/properties/layout-properties.model';
import {getOptionalProperty, uniqueId} from '../../../interpreter-utils';
import {ControlModelReader} from './control-model-reader';
import {LayoutReader} from './layout-reader';
import {MetaControl} from '../../../model/meta/meta-control';
import {TitleModel} from '../../..';
import {ReadingContext} from '../../reading-context';

export class CellModelReader extends LayoutReader {

    constructor(properties: LayoutPropertiesModel, controlResolver: (key: string) => MetaControl,
                context: ReadingContext) {
        super('cells', properties, controlResolver, context,
            new ControlModelReader(properties, controlResolver, context));
    }

    protected readValue(value: object): CellModel[] {
        if (!Array.isArray(value)) {
            throw new TypeError('One of the rows of the layout is not an array');
        }
        return value.map(valueObject => {
            const id = getOptionalProperty(valueObject, 'id', uniqueId());
            const cellModel = new CellModel(
                id,
                this.properties.getCellProperties(id),
                getOptionalProperty(valueObject, 'grid'),
                getOptionalProperty(valueObject, 'title', new TitleModel()),
                getOptionalProperty(valueObject, 'control')
            );
            this.correctControlProperties(cellModel);
            return cellModel;
        });
    }

    private correctControlProperties(cellModel: CellModel) {
        if (this.properties.hasControlProperties(cellModel.id) && cellModel.controlModel) {
            const generatedId: string = cellModel.controlModel.properties.generatedId;
            cellModel.controlModel.properties = this.properties.getControlProperties(cellModel.id);
            cellModel.controlModel.properties.generatedId = generatedId;
        } else if (this.properties.hasControlProperties(cellModel.id)) {
            console.warn(`cell with ${cellModel.id} had control properties defined but had no control`);
        }
    }

}

import {ControlGroup} from '@synisys/idm-dynamic-controls-metadata';

export class MetaControl {
    public columnCount: number;

    constructor(
        public readonly selector: string,
        public readonly name: string,
        public readonly template: string,
        columnCount: number = 4,
        public readonly isHeader = false,
        public readonly iconInfo?: string,
        public readonly settings?: ControlMetaSettings,
        public readonly groups?: ControlGroup[],
        public readonly defaultActions?: object,
        public readonly isFieldBound?: boolean,
        public readonly moduleKey?: string,
        public readonly isSubwayMap?: boolean,
        public readonly nameKey?: string
    ) {
        this.columnCount = columnCount ? columnCount : 4;
    }
}

export interface ControlMetaSettings {
    main: MetaControl;
    central?: MetaControl;
    bottom?: MetaControl;
}

import {LayoutPropertiesModel} from '../../../model/properties';
import {FormTitleModel, ReadableNode, TitleModel} from '../../../model/layout';
import {LayoutReader} from './layout-reader';
import {ReadingContext} from '../../reading-context';

export class TitleReader extends LayoutReader {

    constructor(properties: LayoutPropertiesModel, context: ReadingContext,) {
        super('title', properties, null, context);
    }

    protected readValue(value: object): ReadableNode {
        if (!value) {
            return null;
        }
        if (value.hasOwnProperty('fields')) {
            return new FormTitleModel(value['fields']);
        }
        return TitleModel.fromJSON(value);
    }
}

import {PropertiesNode} from './properties-node';

export class VisibilityCondition extends PropertiesNode {
    constructor(
        public readonly predicate: VisibilityConditionPredicate,
        private readonly rawData: object
    ) {
        super('');
    }

    public toJS(): object {
        return this.rawData;
    }
}

export interface VisibilityConditionContext {
    readonly entity;
    readonly actions;
    readonly queryParams?;
    readonly popupEditMode?;
    readonly item?;
    readonly isEditForm?;
}

export type VisibilityConditionPredicate = (
    context: VisibilityConditionContext
) => boolean;

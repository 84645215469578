import {Component, Injectable, Type} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {Collection, List, Map} from 'immutable';
import {isNil} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';
import {map, publishReplay, refCount, switchMap, tap} from 'rxjs/operators';
import {createLogger} from './interpreter-utils';
import {MetaControl} from './model/meta';
import {ModuleLoadingService} from './module-loading.service';

const logger = createLogger('module-registry');

type ModuleAndSummary = {
    type: Type<object>;
    moduleKey: string;
    summary: Function[];
};
type ModuleKeyAndType = {type: Type<object>; moduleKey: string};

@Injectable()
export class ModuleRegistryService {
    public static extractControlMetadata(
        type: Type<object>,
        moduleKey?: string
    ): MetaControl {
        const controlMetadata: ControlMetadata = Reflect['getMetadata'](
            'dynamic:module',
            type
        );
        if (controlMetadata) {
            const component = <Component>type['__annotations__'][0];
            return new MetaControl(
                component.selector,
                controlMetadata.name,
                controlMetadata.template,
                controlMetadata.cellCount,
                controlMetadata.isHeader,
                controlMetadata.iconInfo,
                controlMetadata.settings
                    ? {
                          main: controlMetadata.settings.main
                              ? ModuleRegistryService.extractControlMetadata(
                                    controlMetadata.settings.main
                                )
                              : undefined,
                          central: controlMetadata.settings.central
                              ? ModuleRegistryService.extractControlMetadata(
                                    controlMetadata.settings.central
                                )
                              : undefined,
                          bottom: controlMetadata.settings.bottom
                              ? ModuleRegistryService.extractControlMetadata(
                                    controlMetadata.settings.bottom
                                )
                              : undefined,
                      }
                    : undefined,
                controlMetadata.groups,
                controlMetadata.defaultActions,
                controlMetadata.isFieldBound,
                moduleKey,
                controlMetadata.isSubwayMap,
                controlMetadata.nameKey
            );
        } else {
            return undefined;
        }
    }

    private static extractModuleMetadata(
        moduleAndSummary: ModuleAndSummary
    ): ControlAndType[] {
        const result: ControlAndType[] = [];
        if (
            moduleAndSummary.type !== undefined &&
            moduleAndSummary.type['__annotations__'] !== undefined
        ) {
            for (const type of moduleAndSummary.type['__annotations__'][0]
                .exports) {
                const metaControl = ModuleRegistryService.extractControlMetadata(
                    type,
                    moduleAndSummary.moduleKey
                );
                if (metaControl) {
                    result.push({
                        control: metaControl,
                        controlType: type,
                        moduleType: moduleAndSummary.type,
                        summary: moduleAndSummary.summary,
                    });
                }
            }
        } else if (moduleAndSummary.type === undefined) {
            logger.error('one of module types was undefined');
        } else if (moduleAndSummary.type['__annotations__'] === undefined) {
            logger.error(
                'no annotation was found for %o type',
                moduleAndSummary.type
            );
        }
        return result;
    }

    public constructor(private readonly loader: ModuleLoadingService) {}

    public findControl(key: string): Observable<MetaControl> {
        logger.info('loading control by key %s', key);
        return this.allControlsMapped().pipe(
            map(controlMap => controlMap.get(key)),
            tap(_ => logger.info('successfully loaded control by key %s', key))
        );
    }

    public findModuleAndControlType(
        key: string,
        fixedModuleKeys?: string[]
    ): Observable<ControlAndType> {
        logger.info('loading module by key %s', key);
        return this.controlMetaAndTypeArray(fixedModuleKeys).pipe(
            map(typeAndControls =>
                typeAndControls.find(value => value.control.selector === key)
            ),
            tap(_ => logger.info('successfully loaded module by key %s', key))
        );
    }

    public availableControls(
        fixedModuleKeys?: string[]
    ): Observable<Collection.Indexed<MetaControl>> {
        return this.allControlsMapped(fixedModuleKeys).pipe(
            map(controlMap => controlMap.valueSeq())
        );
    }

    public allControlsMapped(
        fixedModuleKeys?: string[]
    ): Observable<Map<string, MetaControl>> {
        return this.controlMetaAndTypeArray(fixedModuleKeys).pipe(
            map(metasAndType => {
                return Map<string, MetaControl>().withMutations(mutable => {
                    metasAndType.forEach(meta =>
                        mutable.set(meta.control.selector, meta.control)
                    );
                });
            }),
            publishReplay(1),
            refCount()
        );
    }

    private controlMetaAndTypeArray(
        fixedModuleKeys?: string[]
    ): Observable<ControlAndType[]> {
        return this.getProperModuleKeys(fixedModuleKeys).pipe(
            switchMap(modules => {
                return zip(
                    ...modules
                        .map(module => {
                            return zip(
                                this.getModuleKeyAndType(module),
                                ...this.loader.loadSummariesIfAvailable(module),
                                (
                                    moduleKeyAndType: ModuleKeyAndType,
                                    ...values: Function[]
                                ) => {
                                    // const moduleKeyAndType = values[0] as ModuleKeyAndType;
                                    const summary = values as Function[];
                                    return {
                                        type: moduleKeyAndType.type,
                                        moduleKey: moduleKeyAndType.moduleKey,
                                        summary: summary,
                                    };
                                }
                            );
                        })
                        .toArray()
                );
            }),
            map(data =>
                data
                    .filter(module => !!module)
                    .map(moduleType =>
                        ModuleRegistryService.extractModuleMetadata(moduleType)
                    )
            ),
            map(matrix => [].concat(...matrix)),
            publishReplay(1),
            refCount()
        );
    }

    private getProperModuleKeys(
        fixedModuleKeys?: string[]
    ): Observable<Collection.Indexed<string>> {
        if (!isNil(fixedModuleKeys) && fixedModuleKeys.length > 0) {
            return of(List(fixedModuleKeys));
        }
        return this.loader.availableModules();
    }

    private getModuleKeyAndType(
        moduleKey: string
    ): Observable<ModuleKeyAndType> {
        return this.loader.loadModule(moduleKey).pipe(
            map((moduleType: Type<object>) => {
                return {
                    moduleKey: moduleKey,
                    type: moduleType,
                };
            })
        );
    }
}

export interface ControlAndType {
    control: MetaControl;
    summary: Function[];
    controlType: Type<object>;
    moduleType: Type<object>;
}

import {ControlModel} from './control-model';
import {GridModel} from './grid-model';
import {ReadableNode} from './readable-node';
import {TitleModel} from './title-model';
import {CellPropertiesModel} from '../..';

export class CellModel extends ReadableNode {
    constructor(
        public readonly id: string,
        public readonly properties: CellPropertiesModel,
        public readonly grid?: GridModel,
        public readonly title?: TitleModel,
        public readonly controlModel?: ControlModel
    ) {
        super();
    }

    public isEmpty(): boolean {
        return (
            !this.grid && this.controlModel && this.controlModel.hasDeletedField
        );
    }

    public toJSON(): object {
        return {
            id: this.id,
            grid: this.grid,
            title: this.title,
            control: this.controlModel,
        };
    }
}

import {ReadableNode} from './readable-node';
import {getRequiredProperty} from '../../interpreter-utils';
import {GridModel} from './grid-model';

export class PageLayoutModel extends ReadableNode {
    public static fromJSON(value: object, pageName: string): PageLayoutModel {
        return new PageLayoutModel(
            pageName,
            getRequiredProperty(value, `content.grid`),
        );
    }

    constructor(public readonly pageName: string,
                public readonly content: GridModel) {
        super();
    }

    public toJSON(): object {
        return {
            [this.pageName]: {
                content: {grid: this.content},
            }
        };
    }

}

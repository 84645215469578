import {LayoutPropertiesModel} from '../../../model/properties/layout-properties.model';
import {PropertiesNode} from '../../..';

export abstract class PropertiesReader {
    protected constructor(private readonly key: string,
                          public readonly properties: LayoutPropertiesModel,
                          private readonly predecessor?: PropertiesReader) {

    }

    public read(key: string, value: object): PropertiesNode | PropertiesNode[] | object {
        if (key === this.key) {
            return this.readValue(value);
        } else if (this.predecessor) {
            return this.predecessor.read(key, value);
        } else {
            return value;
        }
    }

    protected abstract readValue(value: object): PropertiesNode | PropertiesNode[];
}

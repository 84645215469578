import {ReadableNode} from './readable-node';
import {ControlPropertiesModel, MetaControl} from '../..';
import {InterpreterMetaField} from './kb-mock/interpreter-meta-field';
import {forIn, set, isEmpty, some, values, isNil} from 'lodash';

export class ControlModel extends ReadableNode {
    private static convertFields(
        fields: FieldsObject
    ): { [key: string]: string } {
        const result: { [key: string]: string } = {};
        forIn(fields, (value, key) =>
            set(result, key, value.metaFieldId.systemName)
        );
        return result;
    }

    private _hasDeletedField: boolean;

    constructor(
        public readonly id: string,
        public readonly fields: FieldsObject,
        public readonly selector: string,
        public readonly bindings: object,
        public readonly actions: object,
        private _properties: ControlPropertiesModel,
        public readonly metaControl: MetaControl,
        public readonly isConfigurable: boolean = false
    ) {
        super();
        this.actions = isEmpty(actions) ? metaControl.defaultActions : actions;
        this._hasDeletedField = some(values(this.fields), isNil);
    }

    get properties(): ControlPropertiesModel {
        return this._properties;
    }

    set properties(value: ControlPropertiesModel) {
        this._properties = value;
    }

    get hasDeletedField(): boolean {
        return this._hasDeletedField;
    }

    public toJSON(): object {
        return {
            id: this.id,
            fields: ControlModel.convertFields(this.fields),
            selector: this.selector,
            bindings: this.bindings,
            actions: this.actions,
            isConfigurable: this.isConfigurable,
        };
    }
}

export declare interface FieldsObject {
    [key: string]: InterpreterMetaField;
}

import {VisibilityConditionPredicate} from '../../../model/properties';
import {allArrayItemsArePlainObjects, JsonData} from '../../../interpreter-utils';
import {isPlainObject} from 'lodash';

export abstract class ConditionReader {
    protected constructor(private readonly predecessor?: ConditionReader) {

    }

    public read(key: string,
                value: JsonData | (string | number)[]): VisibilityConditionPredicate | SimplePredicate {
        if (this.isSupported(key)) {
            if (isPlainObject(value)) {
                value = Object.keys(value).map(objKey => value[objKey])[0];
            } else if (Array.isArray(value) && allArrayItemsArePlainObjects(value)) {
                value = value.map(item => Object.keys(item).map(objKey => item[objKey])[0]);
            }
            return this.internalRead(key, value);
        } else if (this.predecessor) {
            return this.predecessor.read(key, value);
        } else {
            throw new Error(`predecessor was not defined for reader`);
        }
    }

    protected abstract isSupported(key: string): boolean;

    protected abstract internalRead(key: string,
                                    value: JsonData | (string | number)[]):
        VisibilityConditionPredicate | SimplePredicate;

}

export type SimplePredicate = (value: number | string | boolean | undefined | null) => boolean;

import {InterpreterMetaFieldId} from './interpreter-meta-field-id';

export interface InterpreterMetaField {
    metaFieldId: InterpreterMetaFieldId;
    displayNameMsgId: string;
    type: InterpreterMetaFieldType;
    metaFieldTypeStr: string;
    isMultiline: boolean;
    compoundCategorySystemName: string;
    maxLength: number;
    fieldPrefix: string;
    isSystemField: boolean;

    getSystemName(): string;

    getMaxLength(): number;

    getFieldPrefix(): string;

    setFieldPrefix(value: string): void;

    getMetaFieldId(): InterpreterMetaFieldId;

    getDisplayNameMsgId(): string;

    getType(): InterpreterMetaFieldType;

    getCompoundCategorySystemName(): string;

    getIsMultiline(): boolean;

    getIsSystemField(): boolean;

    toString();
}

export enum InterpreterMetaFieldType {
    INTEGER_IDENTITY = 0,
    INTEGER = 1,
    STRING = 2,
    MULTILINGUAL_STRING = 3,
    DATE = 4,
    DATE_TIME = 5,
    BOOLEAN = 6,
    CLASSIFIER = 7,
    PARENT = 8,
    MULTI_SELECT = 9,
    SUB_ENTITY = 10,
    MAIN_ENTITY = 11,
    DECIMAL = 12,
    BIG_DECIMAL = 13,
    INTEGER_INSTANCE = 14,
    DOCUMENT = 15,
    MULTILINESTRING = 16,
    WORKFLOW_STATE = 17,
    USER = 18,
    LOCAL_DATE = 19,
    LOCAL_DATE_TIME = 20,
}

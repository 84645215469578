import {ControlPropertiesModel, LayoutPropertiesModel} from '../../../model/properties';
import {PropertiesReader} from './properties-reader';
import {getOptionalProperty, PropertiesNode} from '../../..';

export class ControlPropertiesReader extends PropertiesReader {

    constructor(properties: LayoutPropertiesModel) {
        super('controlProperties', properties);
    }

    protected readValue(value: object): PropertiesNode {
        const controlPropertiesModel = ControlPropertiesModel.fromJS(getOptionalProperty(value, 'controlId'), value);
        if (controlPropertiesModel.id) {
            this.properties.addControlProperties(controlPropertiesModel);
        }
        return controlPropertiesModel;
    }

}

import {CellPropertiesModel} from '../../../model/properties/cell-properties-model';
import {getOptionalProperty} from '../../../interpreter-utils';
import {LayoutPropertiesModel} from '../../../model/properties/layout-properties.model';
import {ControlPropertiesReader} from './control-properties-reader';
import {PropertiesReader} from './properties-reader';
import {ConditionReadingService, ControlPropertiesModel, PropertiesNode} from '../../..';

export class CellPropertiesReader extends PropertiesReader {

    private readonly conditionReader: ConditionReadingService = new ConditionReadingService();

    constructor(properties: LayoutPropertiesModel) {
        super('cells', properties, new ControlPropertiesReader(properties));
    }

    protected readValue(value: object): PropertiesNode[] {
        if (!Array.isArray(value)) {
            throw new TypeError(`Property cells was not an array`);
        }
        return value.map(cellObject => {
            const cellPropertiesModel = CellPropertiesModel.fromJs(cellObject, this.conditionReader);
            this.properties.addCellProperties(cellPropertiesModel);
            this.correctControlProperties(cellPropertiesModel.id, cellObject);
            return cellPropertiesModel;
        });
    }

    private correctControlProperties(cellId, cellObject: object): void {
        let controlProperties = getOptionalProperty(cellObject, 'controlProperties', null);
        if (controlProperties && !controlProperties.id) {
            controlProperties = ControlPropertiesModel.copyWithNewId(cellId, controlProperties);
            this.properties.addControlProperties(controlProperties);
            cellObject['controlProperties'] = controlProperties;
        }
    }

}

import {GridPropertiesModel} from './grid-properties-model';
import {RowPropertiesModel} from './row-properties-model';
import {CellPropertiesModel} from './cell-properties-model';
import {ControlPropertiesModel} from './control-properties-model';
import {Map} from 'immutable';

export class LayoutPropertiesModel {
    private gridProperties: Map<string, GridPropertiesModel> = Map<string, GridPropertiesModel>().asMutable();
    private rowProperties: Map<string, RowPropertiesModel> = Map<string, RowPropertiesModel>().asMutable();
    private cellProperties: Map<string, CellPropertiesModel> = Map<string, CellPropertiesModel>().asMutable();
    private controlProperties: Map<string, ControlPropertiesModel> = Map<string, ControlPropertiesModel>().asMutable();

    public getGridProperties(id: string): GridPropertiesModel {
        return this.gridProperties.get(id, GridPropertiesModel.createEmpty(id));
    }

    public getRowProperties(id: string): RowPropertiesModel {
        return this.rowProperties.get(id, RowPropertiesModel.createEmpty(id));
    }

    public getCellProperties(id: string): CellPropertiesModel {
        return this.cellProperties.get(id, CellPropertiesModel.createEmpty(id));
    }

    public getControlProperties(id: string): ControlPropertiesModel {
        return this.controlProperties.get(id, ControlPropertiesModel.createEmpty(id));
    }

    public addGridProperties(properties: GridPropertiesModel): void {
        this.gridProperties = this.gridProperties.set(properties.id, properties);
    }

    public addRowProperties(properties: RowPropertiesModel): void {
        this.rowProperties = this.rowProperties.set(properties.id, properties);
    }

    public addCellProperties(properties: CellPropertiesModel): void {
        this.cellProperties = this.cellProperties.set(properties.id, properties);
    }

    public addControlProperties(properties: ControlPropertiesModel): void {
        this.controlProperties = this.controlProperties.set(properties.id, properties);
    }

    public hasControlProperties(id: string) {
        return this.controlProperties.has(id);
    }

}

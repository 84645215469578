/**
 * @author Vahagn Lazyan.
 * @since 2.2.0
 */
import {map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {HttpClientWrapper} from '@synisys/idm-authentication-client-js';
import {FormData, FormService} from './form.service';
import {
    FormLayoutModel,
    LayoutReadingService,
    LayoutWritingService,
} from '../interpreter';
import {FormType} from './form-type.enum';
import {StringTemplate} from '@synisys/idm-common-util-frontend';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {combineLatest} from 'rxjs/observable/combineLatest';

@Injectable()
export class HttpFormService extends FormService {
    private readonly URL_ALL_FORMS: StringTemplate = StringTemplate.createTemplate`${'serviceUrl'}/forms`;

    private readonly URL_ALL_FORMS_WITH_PREFIX: StringTemplate = StringTemplate.createTemplate`${'serviceUrl'}/${'prefix'}/forms`;

    private readonly URL_ONE_FORM_BY_ID: StringTemplate = StringTemplate.createTemplate`${'serviceUrl'}/forms/${'formId'}`;

    private readonly URL_ALL_FORM_BY_CATEGORY: StringTemplate = StringTemplate.createTemplate`${'serviceUrl'}/forms?categorySystemName=${'categoryName'}&typeId=${'formType'}`;

    private readonly URL_ALL_FORM_BY_CATEGORY_SUBCATEGORY: StringTemplate = StringTemplate.createTemplate`${'serviceUrl'}/forms?categorySystemName=${'categoryName'}&typeId=${'formType'}&subCategorySystemName=${'subCategorySystemName'}`;

    private readonly FORM_SERVICE_KEY: string = 'form-service-url';

    private formServiceUrl: ReplaySubject<string> = new ReplaySubject<string>(
        1
    );

    constructor(
        private http: HttpClientWrapper,
        applicationPropertyService: ApplicationPropertiesService,
        private readonly layoutReadingService: LayoutReadingService,
        private readonly layoutWritingService: LayoutWritingService
    ) {
        super();
        applicationPropertyService
            .getProperty(this.FORM_SERVICE_KEY)
            .then((url: string) => {
                this.formServiceUrl.next(url);
                this.formServiceUrl.complete();
            });
    }

    public loadForm(
        formName: string,
        formId: number,
        categoryName: string
    ): Observable<FormData> {
        return this.loadFormById(formId);
    }

    public addNewForm(formData: FormData): Observable<FormData> {
        const formCategory =
            formData.formEntityTypeId === FormEntityType.MAIN_FORM
                ? formData.categorySystemName
                : formData.subCategorySystemName;
        return this.formServiceUrl.pipe(
            switchMap(serviceUrl =>
                this.http.post<FormServiceDto>(
                    this.URL_ALL_FORMS.replaceTemplate({serviceUrl}),
                    <FormServiceDto>{
                        id: formData.id,
                        data: this.layoutWritingService.writeForm(
                            formData.model,
                            formCategory
                        ),
                        typeId: formData.type,
                        formSystemName: formData.model.formName,
                        categorySystemName: formData.categorySystemName,
                        subCategorySystemName: formData.subCategorySystemName,
                        name: formData.name,
                        formEntityTypeId: formData.formEntityTypeId,
                        description: formData.description,
                        prefix: formData.prefix,
                    }
                )
            ),
            switchMap(formJson =>
                this.layoutReadingService
                    .readForm(formJson.data, formData.model.category)
                    .pipe(
                        map(model => {
                            return {
                                id: formJson.id,
                                model: model,
                                type: formJson.typeId,
                                description: formJson.description,
                                formEntityTypeId: formJson.formEntityTypeId,
                                name: formJson.name,
                                categorySystemName: formJson.categorySystemName,
                                subCategorySystemName:
                                    formJson.subCategorySystemName,
                                prefix: formJson.prefix,
                            };
                        })
                    )
            )
        );
    }

    public updateForm(formData: FormData): Observable<FormData> {
        return this.updateFormJson(formData);
    }

    public removeForm(formId: number): Observable<object> {
        return this.formServiceUrl.pipe(
            switchMap(serviceUrl =>
                this.http.delete<object>(
                    this.URL_ONE_FORM_BY_ID.replaceTemplate({
                        serviceUrl,
                        formId: formId.toString(),
                    })
                )
            )
        );
    }

    public allFormsByCategoryAndType(
        categoryName: string,
        type: FormType
    ): Observable<FormData[]> {
        return this.formServiceUrl.pipe(
            switchMap(serviceUrl =>
                this.http.get<FormServiceDto[]>(
                    this.URL_ALL_FORM_BY_CATEGORY.replaceTemplate({
                        serviceUrl,
                        categoryName: categoryName,
                        formType: type.valueOf().toString(),
                    })
                )
            ),
            switchMap(formJsons => {
                return combineLatest(formJsons.map(this.extractForm));
            })
        );
    }

    public allSubFormsByCategoryAndType(
        categoryName: string,
        subCategoryName: string,
        type: FormType
    ): Observable<FormData[]> {
        return this.formServiceUrl.pipe(
            switchMap(serviceUrl =>
                this.http.get<FormServiceDto[]>(
                    this.URL_ALL_FORM_BY_CATEGORY_SUBCATEGORY.replaceTemplate({
                        serviceUrl,
                        categoryName: categoryName,
                        subCategorySystemName: subCategoryName,
                        formType: type.valueOf().toString(),
                    })
                )
            ),
            switchMap(formJsons => {
                if (formJsons && formJsons.length > 0) {
                    return combineLatest(formJsons.map(this.extractForm));
                } else {
                    return of([]);
                }
            })
        );
    }

    public loadFormTypeId(
        formName: string,
        formId: number,
        categoryName: string
    ): Observable<number> {
        return this.formServiceUrl.pipe(
            switchMap(serviceUrl =>
                this.http
                    .get<FormServiceDto>(
                        this.URL_ONE_FORM_BY_ID.replaceTemplate({
                            serviceUrl,
                            formId: formId.toString(),
                        })
                    )
                    .pipe(
                        map((formJson: FormServiceDto) => {
                            return formJson.typeId;
                        })
                    )
            )
        );
    }

    public updateFormJson(formData: FormData): Observable<FormData> {
        const formCategory =
            formData.formEntityTypeId === FormEntityType.SUB_FORM
                ? formData.subCategorySystemName
                : formData.categorySystemName;
        return this.formServiceUrl.pipe(
            switchMap(serviceUrl =>
                this.http.patch<FormServiceDto>(
                    this.URL_ALL_FORMS.replaceTemplate({serviceUrl}),
                    <FormServiceDto>{
                        id: formData.id,
                        data: this.layoutWritingService.writeForm(
                            formData.model,
                            formCategory
                        ),
                        categorySystemName: formData.categorySystemName,
                        typeId: formData.type,
                        formSystemName: formData.model.formName,
                        subCategorySystemName: formData.subCategorySystemName,
                        name: formData.name,
                        formEntityTypeId: formData.formEntityTypeId,
                        description: formData.description,
                        prefix: formData.prefix,
                    }
                )
            ),
            switchMap(formJson =>
                this.layoutReadingService
                    .readForm(formJson.data, formData.model.category)
                    .pipe(
                        map(model => {
                            return {
                                id: formJson.id,
                                model: model,
                                type: formJson.typeId,
                                description: formJson.description,
                                formEntityTypeId: formJson.formEntityTypeId,
                                name: formJson.name,
                                categorySystemName: formData.categorySystemName,
                                subCategorySystemName:
                                    formJson.subCategorySystemName,
                                prefix: formJson.prefix,
                            };
                        })
                    )
            )
        );
    }

    public fullUpdateForm(formData: FormData): Observable<FormData> {
        const formCategory =
            formData.formEntityTypeId === FormEntityType.SUB_FORM
                ? formData.subCategorySystemName
                : formData.categorySystemName;
        const prefix = formData.prefix;
        return this.formServiceUrl.pipe(
            switchMap(serviceUrl =>
                this.http.put<FormServiceDto>(
                    prefix
                        ? this.URL_ALL_FORMS_WITH_PREFIX.replaceTemplate({
                              serviceUrl,
                              prefix,
                          })
                        : this.URL_ALL_FORMS.replaceTemplate({serviceUrl}),
                    <FormServiceDto>{
                        id: formData.id,
                        data: this.layoutWritingService.writeForm(
                            formData.model,
                            formCategory
                        ),
                        categorySystemName: formData.categorySystemName,
                        typeId: formData.type,
                        formSystemName: formData.model.formName,
                        subCategorySystemName: formData.subCategorySystemName,
                        name: formData.name,
                        formEntityTypeId: formData.formEntityTypeId,
                        description: formData.description,
                        prefix: formData.prefix,
                    }
                )
            ),
            switchMap(formJson =>
                this.layoutReadingService
                    .readForm(formJson.data, formData.model.category)
                    .pipe(
                        map(model => {
                            return {
                                id: formJson.id,
                                model: model,
                                type: formJson.typeId,
                                description: formJson.description,
                                formEntityTypeId: formJson.formEntityTypeId,
                                name: formJson.name,
                                categorySystemName: formData.categorySystemName,
                                subCategorySystemName:
                                    formJson.subCategorySystemName,
                                prefix: formJson.prefix,
                            };
                        })
                    )
            )
        );
    }

    private loadFormById(formId: number): Observable<FormData> {
        return this.formServiceUrl.pipe(
            switchMap(serviceUrl =>
                this.http
                    .get<FormServiceDto>(
                        this.URL_ONE_FORM_BY_ID.replaceTemplate({
                            serviceUrl,
                            formId: formId.toString(),
                        })
                    )
                    .pipe(
                        switchMap(formJson => {
                            return this.extractForm(formJson);
                        })
                    )
            )
        );
    }

    private extractForm(formJson: FormServiceDto): Observable<FormData> {
        const formCategory =
            formJson.formEntityTypeId === FormEntityType.SUB_FORM
                ? formJson.subCategorySystemName
                : formJson.categorySystemName;
        if (formJson.data && formJson.data !== '') {
            return this.layoutReadingService
                .readForm(formJson.data, formCategory)
                .pipe(
                    map(model => {
                        return {
                            id: formJson.id,
                            model: model,
                            type: formJson.typeId,
                            description: formJson.description,
                            formEntityTypeId: formJson.formEntityTypeId,
                            name: formJson.name,
                            categorySystemName: formJson.categorySystemName,
                            subCategorySystemName:
                                formJson.subCategorySystemName,
                            prefix: formJson.prefix,
                        };
                    })
                );
        }
        return of({
            id: formJson.id,
            model: FormLayoutModel.emptyModel(formJson, formCategory),
            type: formJson.typeId,
            description: formJson.description,
            formEntityTypeId: formJson.formEntityTypeId,
            name: formJson.name,
            categorySystemName: formJson.categorySystemName,
            subCategorySystemName: formJson.subCategorySystemName,
            prefix: formJson.prefix,
        });
    }
}

interface FormServiceDto {
    id: number;
    formSystemName: string;
    categorySystemName: string;
    subCategorySystemName: string;
    name: object;
    description: object;
    typeId: FormType;
    formEntityTypeId: FormEntityType;
    data: string;
    prefix: string;
}

export enum FormEntityType {
    MAIN_FORM = 1,
    SUB_FORM = 2,
    WORKFLOW_FORM = 3,
}

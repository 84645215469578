import {
    ControlModel,
    FieldsObject,
    LayoutPropertiesModel,
    MetaControl,
    ReadableNode,
} from '../../..';
import {
    getOptionalProperty,
    getRequiredProperty,
    uniqueId,
} from '../../../interpreter-utils';
import {LayoutReader} from './layout-reader';
import {TitleReader} from './title-reader';
import {forIn, set, values, isNil} from 'lodash';
import {ReadingContext} from '../../reading-context';

export class ControlModelReader extends LayoutReader {
    public static readonly PREFIX_FOR_CONTROL_ID = 'selenium-test';

    private static convertFields(
        fields: {[key: string]: string},
        context: ReadingContext
    ): FieldsObject {
        const result: FieldsObject = {};
        forIn(fields, (value, key) => {
            if (!isNil(value)) {
                set(result, key, context.fieldProvider(value));
            }
        });
        return result;
    }

    constructor(
        properties: LayoutPropertiesModel,
        controlResolver: (key: string) => MetaControl,
        context: ReadingContext
    ) {
        super(
            'control',
            properties,
            controlResolver,
            context,
            new TitleReader(properties, context)
        );
    }

    protected readValue(value: object): ReadableNode {
        const id = getOptionalProperty(value, 'id', uniqueId());
        const selector: string = getRequiredProperty(value, 'selector');
        const controlProperties = this.properties.getControlProperties(id);
        const fields: {[key: string]: string} = getOptionalProperty<{
            [key: string]: string;
        }>(value, 'fields', {
            field: getOptionalProperty(value, 'fieldSystemName'),
        });
        controlProperties.generatedId = `${
            ControlModelReader.PREFIX_FOR_CONTROL_ID
        }-${values(fields).join('-')}${controlProperties.idSuffix}`;
        return new ControlModel(
            id,
            ControlModelReader.convertFields(fields, this.context),
            selector,
            getOptionalProperty(value, 'bindings', {}),
            getOptionalProperty(value, 'actions', {}),
            controlProperties,
            this.controlResolver(selector),
            getOptionalProperty(value, 'isConfigurable', false)
        );
    }
}

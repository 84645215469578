import {getOptionalProperty, getRequiredProperty} from '../../../interpreter-utils';
import {fromJS} from 'immutable';
import {LayoutPropertiesModel} from '../../../model/properties/layout-properties.model';
import {RowPropertiesModel} from '../../../model/properties/row-properties-model';
import {CellPropertiesReader} from './cell-properties-reader';
import {PropertiesReader} from './properties-reader';
import {ConditionReadingService, PropertiesNode, VisibilityCondition} from '../../..';

export class RowsPropertiesReader extends PropertiesReader {

    private readonly conditionReader: ConditionReadingService = new ConditionReadingService();

    constructor(properties: LayoutPropertiesModel) {
        super('rows', properties, new CellPropertiesReader(properties));
    }

    protected readValue(value: object): PropertiesNode[] {
        if (!Array.isArray(value)) {
            throw new TypeError(`Property cells was not an array`);
        }
        return value.map(rowObject => {
            const visibilityRawData = getOptionalProperty(rowObject, 'visibility', null);
            const rowPropertiesModel = new RowPropertiesModel(
                getRequiredProperty(rowObject, 'id'),
                fromJS(getOptionalProperty(rowObject, 'style', {})),
                fromJS(rowObject),
                new VisibilityCondition(
                    this.conditionReader.readCondition(visibilityRawData),
                    visibilityRawData
                )
            );
            this.properties.addRowProperties(rowPropertiesModel);
            return rowPropertiesModel;
        });
    }

}

import {Injectable, Type} from '@angular/core';
import {Collection, Map} from 'immutable';
import {ModuleLoadingServiceInterface} from './module-loading.service.interface';
import {DynamicPaths, SummaryPaths} from './loader-types';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';

@Injectable()
export abstract class ModuleLoadingService implements ModuleLoadingServiceInterface {
    private readonly pathMap: Map<string, (path?: string) => Observable<Type<any>>>;
    private readonly summaryMap: Map<string, ((path?: string) => Observable<Function>)[]>;

    protected constructor(paths: DynamicPaths, summaryPaths: SummaryPaths = {}) {
        this.pathMap = Map(paths);
        this.summaryMap = Map(summaryPaths);
    }

    public loadModule(modulePath: string): Observable<Type<object>> {
        if (this.pathMap.has(modulePath)) {
            // }
            return this.pathMap.get(modulePath)();
        } else {
            throw new TypeError(`Dependency for path ${modulePath} is not defined`);
        }
    }

    public loadSummariesIfAvailable(modulePath: string): Observable<Function | undefined>[] {
        if (this.summaryMap.has(modulePath)) {
            return this.summaryMap.get(modulePath).map(f => f());
        } else {
            return [];
        }
    }

    public availableModules(): Observable<Collection.Indexed<string>> {
        return of(this.pathMap.keySeq());
    }
}

import {Injectable} from '@angular/core';
import {CellModel, ControlModel, FormLayoutModel, GridModel, PageLayoutModel, RowModel} from '..';

@Injectable()
export class LayoutWritingService {
    private static formPropertiesToJs(form: FormLayoutModel): object {
        let context = {
            grids: [],
            cells: [],
            rows: []
        };
        if (form.header) {
            if (form.header instanceof ControlModel) {
                context = this.controlModelPropertiesToJs(form.header, context);
            } else {
                context = this.gridPropertiesToJs(form.header, context);
            }
        }
        if (form.footer) {
            context = this.gridPropertiesToJs(form.footer, context);
        }
        if (form.subwayMap) {
            context = this.controlModelPropertiesToJs(form.subwayMap, context);
        }
        context = this.gridPropertiesToJs(form.content, context);
        return context;
    }

    private static pagePropertiesToJs(page: PageLayoutModel): object {
        let context = {
            grids: [],
            cells: [],
            rows: []
        };
        context = this.gridPropertiesToJs(page.content, context);
        return context;
    }

    private static gridPropertiesToJs(grid: GridModel, context: WritingContext): WritingContext {
        context.grids.push(grid.properties.toJS());
        grid.rows.forEach(row => context = this.rowPropertiesToJs(row, context));
        return context;
    }

    private static rowPropertiesToJs(row: RowModel, context: WritingContext): WritingContext {
        context.rows.push(row.properties.toJS());
        row.cells.forEach(cell => context = this.cellPropertiesToJs(cell, context));
        return context;
    }

    private static cellPropertiesToJs(cell: CellModel, context: WritingContext): WritingContext {
        context.cells.push({
            id: cell.properties.id,
            cellProperties: cell.properties.toJS(),
            controlProperties: cell.controlModel ?
                cell.controlModel.properties.toJS() : undefined,
        });
        if (cell.grid) {
            context = this.gridPropertiesToJs(cell.grid, context);
        }
        return context;
    }

    private static controlModelPropertiesToJs(controlModel: ControlModel, context: WritingContext): WritingContext {
        context.cells.push({
            id: controlModel.properties.id,
            cellProperties: {},
            controlProperties: controlModel.properties.toJS(),
        });
        return context;
    }

    public writeForm(form: FormLayoutModel, formCategoryName: string): string {
        return JSON.stringify({
            layout: JSON.stringify(form),
            property: JSON.stringify(
                {
                    [formCategoryName]: {
                        [form.formName]: LayoutWritingService.formPropertiesToJs(form)
                    }
                })
        });
    }

    public writePage(page: PageLayoutModel): string {
        return JSON.stringify({
            layout: JSON.stringify(page),
            property: JSON.stringify(
                {
                    [page.pageName]: LayoutWritingService.pagePropertiesToJs(page)
                })
        });
    }
}

interface WritingContext {
    grids: object[];
    cells: object[];
    rows: object[];
}

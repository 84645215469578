import {PropertiesReader} from './properties-reader';
import {LayoutPropertiesModel} from '../../../model/properties/layout-properties.model';
import {GridPropertiesReader} from './grid-properties-reader';
import {PropertiesNode} from '../../..';

export class LayoutPropertiesReader extends PropertiesReader {

    constructor(key: string, properties: LayoutPropertiesModel) {
        super(key, properties, new GridPropertiesReader(properties));
    }

    protected readValue(value: object): PropertiesNode {
        return <PropertiesNode>value;
    }
}

import {GridModel} from './grid-model';
import {ReadableNode} from './readable-node';
import {getOptionalProperty, getRequiredProperty} from '../../interpreter-utils';
import {ControlModel} from './control-model';
import {FormTitleModel} from './form-title-model';
import {isNil} from 'lodash';

export class FormLayoutModel extends ReadableNode {
    public static fromJSON(value: object, category: string): FormLayoutModel {
        const formLayoutModel = Object.keys(getRequiredProperty(value, 'forms')).map(formName => {
            return new FormLayoutModel(
                category,
                formName,
                getRequiredProperty(value, `forms.${formName}.content.grid`),
                getOptionalProperty(value, `forms.${formName}.header.control`,
                    undefined,
                    () => getOptionalProperty(value, `forms.${formName}.header.grid`)),
                getOptionalProperty(value, `forms.${formName}.footer.grid`),
                getOptionalProperty(value, `forms.${formName}.title`, new FormTitleModel([])),
                getOptionalProperty(value, `moduleKeys`, undefined),
                getOptionalProperty(value, `forms.${formName}.subwayMap.control`, undefined),
            );
        })[0];
        return formLayoutModel;
    }

    public static emptyModel(formJson: { formSystemName: string }, categoryName: string) {
        return new FormLayoutModel(categoryName, formJson.formSystemName,
            undefined, undefined, undefined, new FormTitleModel([]));
    }

    constructor(public readonly category: string,
                public readonly formName: string,
                public readonly content: GridModel,
                public readonly header: ControlModel | GridModel,
                public readonly footer: GridModel,
                public readonly title: FormTitleModel,
                public readonly moduleKeys?: string[],
                public readonly subwayMap?: ControlModel,
                ) {
        super();
    }

    public toJSON(): object {
        return {
            [this.category]: {
                forms: {
                    [this.formName]: {
                        content: {grid: this.content},
                        header: this.header instanceof ControlModel ? {control: this.header} : {grid: this.header},
                        subwayMap: {control: this.subwayMap} ,
                        footer: {grid: this.footer},
                        title: this.title
                    }
                },
                moduleKeys: !isNil(this.moduleKeys) ? this.moduleKeys : []
            }
        };
    }

}

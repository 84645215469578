import {Logger} from '@synisys/skynet-store-utilities';
import {forOwn, isPlainObject} from 'lodash';
export const debug = require('debug');

export function getProperty<T>(obj: object, property: string): T {
    const firstDot = property.indexOf('.');
    if (firstDot >= 0) {
        return getProperty(
            getProperty(obj, property.substring(0, firstDot)),
            property.substring(firstDot + 1)
        );
    } else {
        if (obj.hasOwnProperty(property)) {
            return <T>obj[property];
        } else {
            throw new Error(`property ${property} was not found`);
        }
    }
}

export function getOptionalProperty<T>(
    obj: object,
    property: string,
    defaultValue?: T,
    defaultValueProvider?: () => T
): T {
    const firstDot = property.indexOf('.');
    if (firstDot >= 0) {
        return getOptionalProperty(
            getOptionalProperty(obj, property.substring(0, firstDot), {}),
            property.substring(firstDot + 1),
            defaultValue,
            defaultValueProvider
        );
    } else {
        const value: T = obj.hasOwnProperty(property)
            ? getProperty(obj, property)
            : undefined;
        if (value !== null && value !== undefined) {
            return value;
        } else {
            return defaultValue
                ? defaultValue
                : defaultValueProvider
                ? defaultValueProvider()
                : undefined;
        }
    }
}

export function getRequiredProperty<T>(obj: object, property: string): T {
    const value: T = getProperty(obj, property);
    if (value !== null && value !== undefined) {
        return value;
    } else {
        throw new Error(`value of property ${property} was not defined`);
    }
}

export function traverseObject<T>(
    obj: object,
    callback: (key: string, value: JsonData | (string | number)[]) => object
): object {
    const result: object = {};
    forOwn(obj, (value, key) => {
        if (isPlainObject(value)) {
            value = traverseObject(value, callback);
        } else if (
            Array.isArray(value) &&
            allArrayItemsArePlainObjects(<any[]>value)
        ) {
            value = value.map(item => traverseObject(item, callback));
        }
        result[key] = callback(key, value);
    });
    return result;
}

export function allArrayItemsArePlainObjects(array: any[]): boolean {
    return array.every(item => isPlainObject(item));
}

// tslint:disable
export function uniqueId(): string {
    return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
// tslint:enable
export type JsonData = string | number | boolean | object;

export function createLogger(scope: string): Logger {
    return new Logger('dynamic-layout-interpreter', scope);
}

import {LayoutPropertiesModel} from '../../../model/properties/layout-properties.model';
import {GridModel} from '../../../model/layout/grid-model';
import {getOptionalProperty, getRequiredProperty, uniqueId} from '../../../interpreter-utils';
import {LayoutReader} from './layout-reader';
import {RowModelReader} from './row-model-reader';
import {MetaControl} from '../../../model/meta/meta-control';
import {GridType} from '../../../model/layout/grid-type';
import {ReadingContext} from '../../reading-context';
import {isNil} from 'lodash';

export class GridModelReader extends LayoutReader {

    constructor(properties: LayoutPropertiesModel, controlResolver: (key: string) => MetaControl,
                context: ReadingContext) {
        super('grid', properties, controlResolver, context, new RowModelReader(properties, controlResolver, context));
    }

    protected readValue(value: object | undefined | null): GridModel {
        if (!isNil(value)) {
            const id = getOptionalProperty(value, 'id', uniqueId());
            return new GridModel(
                id,
                getRequiredProperty(value, 'rows'),
                getOptionalProperty(value, 'type', GridType.FLAT),
                this.properties.getGridProperties(id)
            );
        } else {
            return GridModel.empty();
        }
    }

}

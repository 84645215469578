import {ReadableNode} from './readable-node';
import {RowModel} from './row-model';
import {GridPropertiesModel} from '../properties/grid-properties-model';
import {GridType} from './grid-type';

export class GridModel extends ReadableNode {

    public static empty(): GridModel {
        return new GridModel('N/A', [], GridType.FLAT, undefined);
    }
    constructor(public readonly id: string,
                public readonly rows: RowModel[],
                public readonly type: GridType,
                public readonly properties: GridPropertiesModel) {
        super();
    }

    public toJSON(): object {
        return {
            id: this.id,
            rows: this.rows,
            type: this.type
        };
    }
}

import {ConditionReader, SimplePredicate} from './condition-reader';
import {ConditionFieldReader} from './condition-field-reader';
import {Seq} from 'immutable';
import {gt, gte, lt, lte} from 'lodash';

export class SimpleConditionReader extends ConditionReader {

    private static supportedKeys: Seq.Set<string> = Seq.Set(
        ['$gt', '$lt', '$gte', '$lte', '$in', '$startswith', '$isnull']
    );

    constructor() {
        super(new ConditionFieldReader());
    }

    protected internalRead(key: string, value: string | number | (string | number)[]): SimplePredicate {
        switch (key) {
            case '$startswith':
                return (property: string) => property ? property.startsWith(<string>value) : false;
            case '$gt':
                return property => property ? gt(property, value) : false;
            case '$lt':
                return property => property ? lt(property, value) : false;
            case '$gte':
                return property => property ? gte(property, value) : false;
            case '$lte':
                return property => property ? lte(property, value) : false;
            case '$in':
                if (!Array.isArray(value)) {
                    throw new Error('parameter of in operator was not an array');
                }
                return property => property ? value.indexOf(<number | string>property) >= 0 : false;
        }
    }

    protected isSupported(key: string): boolean {
        return SimpleConditionReader.supportedKeys.contains(key);
    }

}

import {LayoutPropertiesModel} from '../../../model/properties/layout-properties.model';
import {RowModel} from '../../../model/layout/row-model';
import {getOptionalProperty, getRequiredProperty, uniqueId} from '../../../interpreter-utils';
import {CellModelReader} from './cell-model-reader';
import {LayoutReader} from './layout-reader';
import {MetaControl} from '../../../model/meta/meta-control';
import {ReadingContext} from '../../reading-context';

export class RowModelReader extends LayoutReader {

    constructor(properties: LayoutPropertiesModel, controlResolver: (key: string) => MetaControl,
                context: ReadingContext) {
        super('rows', properties, controlResolver, context, new CellModelReader(properties, controlResolver, context));
    }

    protected readValue(value: object): RowModel[] {
        if (!Array.isArray(value)) {
            throw new TypeError('One of the rows of the layout is not an array');
        }
        return value.map(valueObject => {
            const id = getOptionalProperty(valueObject, 'id', uniqueId());
            return new RowModel(
                id,
                getRequiredProperty(valueObject, 'cells'),
                this.properties.getRowProperties(id),
                getOptionalProperty(valueObject, 'title', null),
            );
        });
    }

}

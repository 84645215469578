import {Map} from 'immutable';
import {JsonData} from '../../interpreter-utils';
import {PropertiesNode} from './properties-node';
import {VisibilityCondition} from './visibility-condition';

export class GridPropertiesModel extends PropertiesNode {

    public static createEmpty(id: string): GridPropertiesModel {
        return new GridPropertiesModel(id, Map());
    }

    constructor(id: string,
                public readonly generalProperties: Map<string, JsonData>,
                public readonly visibility: VisibilityCondition = new VisibilityCondition(undefined, undefined)) {
        super(id);
    }

    public toJS(): object {
        return this.generalProperties.set('id', this.id).set('visibility', this.visibility.toJS()).toJS();
    }
}

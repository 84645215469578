import {first, map, switchMap} from 'rxjs/operators';
import {FormLayoutModel, PageLayoutModel} from '../model/layout';
import {LayoutPropertiesModel} from '../model/properties';
import {LayoutPropertiesReader} from './readers/properties';
import {FormLayoutModelReader, PageLayoutModelReader} from './readers/layout';
import {ModuleRegistryService} from '../module-registry.service';
import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {getRequiredProperty} from '../interpreter-utils';

import {InterpreterMetaField, LayoutReader, MetaControl} from '..';
import {Map} from 'immutable';
import {ReadingContext} from './reading-context';

export declare type FieldsProvider = (
    category: string
) => Observable<InterpreterMetaField[]>;
export const formModuleRegistryKey: InjectionToken<ModuleRegistryService> = new InjectionToken<
    ModuleRegistryService
>('module registry service for forms');
export const pageModuleRegistryKey: InjectionToken<ModuleRegistryService> = new InjectionToken<
    ModuleRegistryService
>('module registry service for pages');
export const fieldsProviderKey: InjectionToken<FieldsProvider> = new InjectionToken<
    FieldsProvider
>('fields provider function that returns fields for given category');

@Injectable()
export class LayoutReadingService {
    private static read<T>(
        formJson: string,
        category: string,
        readerProvider: (b, c) => LayoutReader<T>,
        controlsProvider: (
            fixedKeys?: string[]
        ) => Observable<Map<string, MetaControl>>
    ): Observable<T> {
        const formObject = JSON.parse(formJson) as {
            layout: string;
            property: string;
        };
        const properties: LayoutPropertiesModel = new LayoutPropertiesModel();
        const propertiesReader: LayoutPropertiesReader = new LayoutPropertiesReader(
            category,
            properties
        );
        JSON.parse(formObject.property, (key, value) =>
            propertiesReader.read(key, value)
        );
        const fixedKeys = LayoutReadingService.getFixedKeys(formObject.layout);
        return controlsProvider(fixedKeys).pipe(
            map(mappedControl => {
                const layoutReader = readerProvider(properties, key => {
                    if (mappedControl.has(key)) {
                        return mappedControl.get(key);
                    } else {
                        throw new Error(
                            `control with ${key} selector was not found in any of available modules`
                        );
                    }
                });
                return getRequiredProperty(
                    JSON.parse(formObject.layout, (key, value) =>
                        layoutReader.read(key, value)
                    ),
                    category
                );
            })
        );
    }

    private static getFixedKeys(layoutStr: string): string[] {
        if (layoutStr.includes('moduleKeys')) {
            const keysSubStr = layoutStr.substring(
                layoutStr.lastIndexOf('moduleKeys')
            );
            return JSON.parse(
                keysSubStr.substr(
                    keysSubStr.indexOf('['),
                    keysSubStr.indexOf(']') - keysSubStr.indexOf('[') + 1
                )
            );
        }
        return undefined;
    }

    private readonly fieldsProvider: FieldsProvider;

    constructor(
        @Optional()
        @Inject(formModuleRegistryKey)
        private readonly formModuleRegistry: ModuleRegistryService,
        @Optional()
        @Inject(pageModuleRegistryKey)
        private readonly pageModuleRegistry: ModuleRegistryService,
        @Inject(fieldsProviderKey) fieldsProvider: any
    ) {
        this.fieldsProvider = fieldsProvider;
    }

    public readForm(
        formJson: string,
        category: string
    ): Observable<FormLayoutModel> {
        return this.fieldsProvider(category).pipe(
            map(fields =>
                Map<string, InterpreterMetaField>(
                    fields.map((field): [string, InterpreterMetaField] => [
                        field.getMetaFieldId().getSystemName(),
                        field,
                    ])
                )
            ),
            switchMap(fieldMap =>
                LayoutReadingService.read(
                    formJson,
                    category,
                    (b, c) =>
                        new FormLayoutModelReader(
                            category,
                            b,
                            c,
                            new ReadingContext(field => fieldMap.get(field))
                        ),
                    (fixedModuleKeys?: string[]) =>
                        this.formModuleRegistry.allControlsMapped(
                            fixedModuleKeys
                        )
                )
            ),
            first()
        );
    }

    public readPage(
        formJson: string,
        name: string
    ): Observable<PageLayoutModel> {
        return LayoutReadingService.read(
            formJson,
            name,
            (b, c) =>
                new PageLayoutModelReader(
                    name,
                    b,
                    c,
                    new ReadingContext(_ => {
                        return undefined;
                    })
                ),
            () => this.pageModuleRegistry.allControlsMapped()
        );
    }
}

import {getOptionalProperty} from '../../interpreter-utils';

export class TitleModel {
    public static fromJSON(value: TitleJson): TitleModel {
        return new TitleModel(
            getOptionalProperty(value, 'title_msg', undefined),
            getOptionalProperty(value, 'title_src', undefined),
            getOptionalProperty(value, 'custom_text', undefined),
        );
    }

    constructor(public readonly titleMessage?: string,
                public readonly titleMessageSource?: string,
                public readonly customText?: {[key: number]: string}) {
    }

    public toJSON(): TitleJson {
        return {
            title_msg  : this.titleMessage,
            title_src  : this.titleMessageSource,
            custom_text: this.customText,
        };
    }

}

export interface TitleJson {

    title_msg?: string;
    title_src?: string;
    custom_text?: object;
}

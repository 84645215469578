import {FormLayoutModel} from '../../../model/layout/form-layout-model';
import {LayoutReader} from './layout-reader';
import {LayoutPropertiesModel} from '../../../model/properties/layout-properties.model';
import {MetaControl} from '../../../model/meta/meta-control';
import {GridModelReader} from './grid-model-reader';
import {ReadingContext} from '../../reading-context';

export class FormLayoutModelReader extends LayoutReader<FormLayoutModel> {

    constructor(private readonly category: string,
                properties: LayoutPropertiesModel,
                controlResolver: (key: string) => MetaControl,
                context: ReadingContext) {
        super(category, properties, controlResolver, context,
              new GridModelReader(properties, controlResolver, context));
    }

    protected readValue(value: object): FormLayoutModel {
        return FormLayoutModel.fromJSON(value, this.category);
    }

}

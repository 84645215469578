import {Injectable} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {FormType} from './form-type.enum';
import {FormLayoutModel} from '../interpreter';
import {FormEntityType} from './http-form.service';

@Injectable()
export abstract class FormService {
    public abstract loadForm(
        formName: string,
        formId: number,
        categoryName: string
    ): Observable<FormData>;

    public abstract addNewForm(formDtoModel: FormData): Observable<FormData>;

    public abstract loadFormTypeId(
        formName: string,
        formId: number,
        categoryName: string
    ): Observable<number>;

    /**
     * Updates form json.
     * @param formDtoModel
     * @deprecated this is partial update, and will be modified in future.
     * Use {@link FormService#updateFormJson} instead.
     */
    public abstract updateForm(formDtoModel: FormData): Observable<FormData>;

    public abstract removeForm(formId: number): Observable<object>;

    public abstract allFormsByCategoryAndType(
        categoryName: string,
        type: FormType
    ): Observable<FormData[]>;

    public abstract allSubFormsByCategoryAndType(
        categoryName: string,
        subCategoryName: string,
        type: FormType
    ): Observable<FormData[]>;

    /**
     * Updates form json.
     * @param formDtoModel
     */
    public abstract updateFormJson(
        formDtoModel: FormData
    ): Observable<FormData>;

    /**
     * Updates form whole data.
     * @param formDtoModel
     */
    public abstract fullUpdateForm(
        formDtoModel: FormData
    ): Observable<FormData>;
}

export interface FormData {
    id: number;
    model: FormLayoutModel;
    type: number;
    categorySystemName: string;
    subCategorySystemName: string;
    name: object;
    description: object;
    formEntityTypeId: FormEntityType;
    prefix: string;
}

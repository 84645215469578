import {fromJS, Map} from 'immutable';
import {getOptionalProperty, getRequiredProperty, JsonData} from '../../interpreter-utils';
import {PropertiesNode} from './properties-node';
import {VisibilityCondition} from './visibility-condition';
import {ConditionReadingService} from '../..';

export class CellPropertiesModel extends PropertiesNode {
    public static createEmpty(id: string): CellPropertiesModel {
        return new CellPropertiesModel(id,
                                       CellPropertiesModel.defaultTemplate,
                                       CellPropertiesModel.count,
                                       Map());
    }

    public static fromJs(cellObject: object, conditionReader: ConditionReadingService): CellPropertiesModel {
        const visibilityRawData = getOptionalProperty(cellObject, 'cellProperties.visibility', null);
        return new CellPropertiesModel(
            getRequiredProperty(cellObject, 'id'),
            getOptionalProperty(cellObject, 'cellProperties.style.template', CellPropertiesModel.defaultTemplate),
            {
                'xs': getOptionalProperty(cellObject, 'cellProperties.column_count.xs', CellPropertiesModel.count.xs),
                's' : getOptionalProperty(cellObject, 'cellProperties.column_count.s', CellPropertiesModel.count.s),
                'm' : getOptionalProperty(cellObject, 'cellProperties.column_count.m', CellPropertiesModel.count.m)
            },
            fromJS(getOptionalProperty(cellObject, 'cellProperties.style', {})),
            new VisibilityCondition(
                conditionReader.readCondition(visibilityRawData),
                visibilityRawData)
        );
    }

    private static defaultTemplate = 'transparent';
    private static count = {
        xs: 4,
        s : 8,
        m : 12,
    };

    constructor(id: string,
                public readonly template: string,
                public readonly columnCount: ColumnCount,
                public readonly style: Map<string, JsonData>,
                public readonly visibility: VisibilityCondition = new VisibilityCondition(undefined, undefined)) {
        super(id);
    }

    public toJS(): object {
        return {
            id          : this.id,
            column_count: this.columnCount,
            style       : this.style.set('template', this.template).toJS(),
            visibility  : this.visibility.toJS()
        };
    }
}

export interface ColumnCount {
    readonly xs: number;
    readonly s: number;
    readonly m: number;
}

import {ConditionReader, SimplePredicate} from './condition-reader';
import {VisibilityConditionPredicate} from '../../../model/properties';
import {getOptionalProperty, getRequiredProperty} from '../../../interpreter-utils';
import {eq, isArray, isBoolean, isNil, isNumber, isString} from 'lodash';

export class ConditionFieldReader extends ConditionReader {

    private static getEntityFieldValue(value: object, parameter: string) {
        if (value['hasProperty'] && value['getProperty'] && value['hasProperty'](parameter)) {
            return value['getProperty'](parameter).value;
        } else if (isArray(value)) {
            return value[parameter];
        } else {
            return getOptionalProperty(value, parameter, undefined);
        }
    }

    protected internalRead(key: string, predicate: SimplePredicate): VisibilityConditionPredicate {
        if (isNumber(predicate) || (isString(predicate) && !predicate.startsWith('actions')) || isBoolean(predicate)) {
            const value = predicate;
            predicate = item => eq(item, value);
        } else if (isNil(predicate)) {
            predicate = item => isNil(item);
        }
        return context => {
            if (isString(predicate) && predicate.startsWith('actions')) {
                const methodName = this.parseActionMethodName(predicate);
                const actions = getRequiredProperty(context, 'actions')
                const methodValue = actions[methodName]();
                predicate = item => eq(item, methodValue);
            } if (key.startsWith('actions')) {
                throw new Error('Not Supported');
            } else if (key.startsWith('entity')) {
                const [, parameter, postfix] = /^entity\.([^.]+)\.?(.*)$/g.exec(key);
                const entity = getRequiredProperty(context, 'entity');
                let entityField = ConditionFieldReader.getEntityFieldValue(entity, parameter);
                if (entityField && postfix !== '') {
                    entityField = this.parseEntityField(entityField, postfix);
                }
                return predicate(entityField);
            } else if (key.startsWith('queryParams')) {
                const [, parameter, postfix] = /^queryParams\.([^.]+)\.?(.*)$/g.exec(key);
                const queryParams = getRequiredProperty(context, 'queryParams');
                let entityField = ConditionFieldReader.getEntityFieldValue(queryParams, parameter);
                if (entityField && postfix !== '') {
                    entityField = this.parseEntityField(entityField, postfix);
                }
                return predicate(entityField);
            } else if (key.startsWith('item')) {
                const [, parameter, postfix] = /^item\.([^.]+)\.?(.*)$/g.exec(key);
                const item = getRequiredProperty(context, 'item');
                let itemField = ConditionFieldReader.getEntityFieldValue(item, parameter);
                if (itemField && postfix !== '') {
                    itemField = this.parseEntityField(itemField, postfix);
                }
                return predicate(itemField);
            } else {
                return predicate(getRequiredProperty(context, key));
            }
        };
    }

    protected isSupported(key: string): boolean {
        return !key.startsWith('$');
    }

    private parseEntityField(obj: object, path: string): object | undefined {
        const [, parameter, postfix] = /^([^.]+)\.?(.*)$/g.exec(path);
        const currValue = ConditionFieldReader.getEntityFieldValue(obj, parameter);
        if (!currValue) {
            return undefined;
        } else if (postfix !== '') {
            return this.parseEntityField(currValue, postfix);
        } else {
            return currValue;
        }
    }

    private parseActionMethodName(value: string): string {
        return value.slice(value.indexOf('.') + 1, value.indexOf('('));
    }
}

import {RowPropertiesModel} from '../properties/row-properties-model';
import {CellModel} from './cell-model';
import {ReadableNode} from './readable-node';
import {TitleModel} from './title-model';

export class RowModel extends ReadableNode {
    constructor(
        public readonly id: string,
        public readonly cells: CellModel[],
        public readonly properties: RowPropertiesModel,
        public readonly title?: TitleModel
    ) {
        super();
    }

    public toJSON(): object {
        return {
            id: this.id,
            title: this.title,
            cells: this.cells.filter(cell => !cell.isEmpty()),
        };
    }
}

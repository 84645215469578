import {traverseObject} from '../../../interpreter-utils';
import {ConditionReader, GroupingConditionReader, VisibilityConditionPredicate} from '../../..';
import {isFunction, values} from 'lodash';

export class ConditionReadingService {

    private static extractFromObject(conditionObject: object): VisibilityConditionPredicate {
        const conditionValues = values(conditionObject);
        if (conditionValues.length !== 1) {
            throw new Error('condition was not parsed properly');
        }
        const potentialCondition: object = conditionValues[0];
        if (isFunction(potentialCondition)) {
            return potentialCondition;
        } else {
            throw new Error('condition was not parsed properly');
        }
    }

    private readonly reader: ConditionReader = new GroupingConditionReader();

    public readCondition(conditionObject: object): VisibilityConditionPredicate {
        return conditionObject ?
               ConditionReadingService.extractFromObject(traverseObject(conditionObject, (key, value) => {
                   return this.reader.read(key, value);
               })) : undefined;
    }
}

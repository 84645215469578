import {ConditionReader} from './condition-reader';
import {Seq} from 'immutable';
import {SimpleConditionReader} from './simple-condition-reader';
import {VisibilityConditionPredicate} from '../../../model/properties';

export class GroupingConditionReader extends ConditionReader {

    private static supportedKeys: Seq.Set<string> = Seq.Set(['$and', '$or', '$not']);

    constructor() {
        super(new SimpleConditionReader());
    }

    protected internalRead(key: string, value: object): VisibilityConditionPredicate {
        switch (key) {
            case '$and':
                if (!Array.isArray(value)) {
                    throw new Error('parameter of and operator was not an array');
                }
                const andPredicates: VisibilityConditionPredicate[] = value;
                return context => andPredicates.every(predicate => predicate(context));
            case '$or':
                if (!Array.isArray(value)) {
                    throw new Error('parameter of and operator was not an array');
                }
                const orPredicates: VisibilityConditionPredicate[] = value;
                return context => orPredicates.some(predicate => predicate(context));
            case '$not':
                const notPredicate: VisibilityConditionPredicate = <VisibilityConditionPredicate>value;
                return context => !notPredicate(context);
        }
    }

    protected isSupported(key: string): boolean {
        return GroupingConditionReader.supportedKeys.contains(key);
    }

}

/**
 * @author Vahagn Lazyan.
 * @since 2.2.0
 */
import {switchMap, map, first, takeUntil} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';

import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {FormData, FormService} from './form.service';
import {LayoutReadingService} from '../interpreter';
import {FormType} from './form-type.enum';
import {pick} from 'lodash';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {Observable} from 'rxjs/Observable';
import {zip} from 'rxjs/observable/zip';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class JsonBasedFormService extends FormService implements OnDestroy {
    protected kbJSON: ReplaySubject<string> = new ReplaySubject<string>(1);
    protected propsJSON: ReplaySubject<string> = new ReplaySubject<string>(1);

    private readonly kbJSONKey: string = 'dynamic-forms-kb-json-path';
    private readonly propsJSONKey: string =
        'dynamic-forms-properties-json-path';

    private destroySubject$: Subject<void> = new Subject<void>();

    constructor(
        protected http: HttpClient,
        protected applicationPropertiesService: ApplicationPropertiesService,
        private readonly readingService: LayoutReadingService
    ) {
        super();
        this.init();
    }

    public ngOnDestroy() {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    public loadForm(
        formName: string,
        formId: number,
        categoryName: string
    ): Observable<FormData> {
        let typeId: number;
        return combineLatest<string, string>(this.kbJSON, this.propsJSON).pipe(
            first(),
            map((data: [string, string]): string => {
                const layoutObject: object = this.grabCorrectLayoutJson(
                    data[0],
                    formName,
                    categoryName
                );
                typeId = layoutObject['typeId'];
                return JSON.stringify({
                    layout: layoutObject['layout'],
                    property: this.grabCorrectPropertyJson(
                        data[1],
                        formName,
                        categoryName
                    ),
                });
            }),
            switchMap((layoutJson: string) => {
                return this.readingService.readForm(layoutJson, categoryName);
            }),
            map(model => {
                return {
                    id: undefined,
                    model: model,
                    type: typeId,
                    description: undefined,
                    formEntityTypeId: undefined,
                    name: undefined,
                    categorySystemName: categoryName,
                    subCategorySystemName: undefined,
                    prefix: undefined,
                };
            })
        );
    }

    public addNewForm(formData: FormData): Observable<FormData> {
        throw Error('Not Implemented Yet');
    }

    public updateForm(formDtoModel: FormData): Observable<FormData> {
        throw Error('Not Implemented Yet');
    }

    public removeForm(formId: number): Observable<object> {
        throw Error('Not Implemented Yet');
    }

    public allSubFormsByCategoryAndType(
        categoryName: string,
        subCategoryName: string,
        type: FormType
    ): Observable<FormData[]> {
        throw new Error('Method not implemented.');
    }

    public allFormsByCategoryAndType(
        categoryName: string,
        type: FormType
    ): Observable<FormData[]> {
        return combineLatest<string, string>(this.kbJSON, this.propsJSON).pipe(
            first(),
            switchMap((data: string[]) => {
                const kb: object = JSON.parse(data[0]);
                const obj: string = JSON.stringify({
                    layout: data[0],
                    property: data[1],
                });

                return zip(
                    ...Object.keys(kb[categoryName].forms)
                        .filter(
                            (form: string) =>
                                kb[categoryName].forms[form].typeId === type
                        )
                        .map((form: string) =>
                            this.createDeFormModel(
                                categoryName,
                                form,
                                data[0],
                                data[1]
                            )
                        )
                );
            })
        );
    }

    public loadFormTypeId(
        formName: string,
        formId: number,
        categoryName: string
    ): Observable<number> {
        return combineLatest<string, string>(this.kbJSON, this.propsJSON).pipe(
            first(),
            map((data: [string, string]): number => {
                const layoutObject: object = this.grabCorrectLayoutJson(
                    data[0],
                    formName,
                    categoryName
                );
                return layoutObject['typeId'];
            })
        );
    }

    public updateFormJson(formDtoModel: FormData): Observable<FormData> {
        throw new Error('Method not implemented.');
    }

    public fullUpdateForm(formDtoModel: FormData): Observable<FormData> {
        throw new Error('Method not implemented.');
    }

    private grabCorrectLayoutJson(
        layoutJson: string,
        formName: string,
        categoryName: string
    ): object {
        const parsedLayout: object = JSON.parse(layoutJson);
        const typeId: object =
            parsedLayout[categoryName]['forms'][formName]['typeId'];
        const layout = {};
        layout[categoryName] = {
            forms: pick(parsedLayout[categoryName]['forms'], [formName]),
        };
        return {layout: JSON.stringify(layout), typeId: typeId};
    }

    private grabCorrectPropertyJson(
        propJson: string,
        formName: string,
        categoryName: string
    ): string {
        const prop = {};
        prop[categoryName] = pick(JSON.parse(propJson)[categoryName], [
            formName,
        ]);
        return JSON.stringify(prop);
    }

    private init(): void {
        this.applicationPropertiesService
            .getProperty(this.kbJSONKey)
            .then((path: string) =>
                this.http
                    .get<object>(path)
                    .pipe(takeUntil(this.destroySubject$))
                    .subscribe(
                        (data: object) =>
                            this.kbJSON.next(JSON.stringify(data)),
                        (error: object) => console.error(error)
                    )
            );

        this.applicationPropertiesService
            .getProperty(this.propsJSONKey)
            .then((path: string) =>
                this.http
                    .get<object>(path)
                    .pipe(takeUntil(this.destroySubject$))
                    .subscribe(
                        (data: object) =>
                            this.propsJSON.next(JSON.stringify(data)),
                        (error: object) => console.error(error)
                    )
            );
    }

    private createDeFormModel(
        categorySystemName: string,
        formName: string,
        kbJSON: string,
        propsJSON: string
    ): Observable<FormData> {
        const layoutObject: object = this.grabCorrectLayoutJson(
            kbJSON,
            formName,
            categorySystemName
        );
        const typeId = layoutObject['typeId'];
        const data: string = JSON.stringify({
            layout: layoutObject['layout'],
            property: this.grabCorrectPropertyJson(
                propsJSON,
                formName,
                categorySystemName
            ),
        });
        return this.readingService.readForm(data, categorySystemName).pipe(
            map(layoutModel => {
                return {
                    id: undefined,
                    type: typeId,
                    model: layoutModel,
                    description: undefined,
                    formEntityTypeId: undefined,
                    name: undefined,
                    categorySystemName,
                    subCategorySystemName: undefined,
                    prefix: undefined,
                };
            })
        );
    }

    private createPageModel(
        formName: string,
        kbJSON: object,
        propsJSON: object
    ): Observable<FormData> {
        const data: string = JSON.stringify({
            layout: kbJSON,
            property: propsJSON,
        });
        return this.readingService.readForm(JSON.stringify(data), null).pipe(
            map(layoutModel => {
                return {
                    id: undefined,
                    type: undefined,
                    model: layoutModel,
                    description: undefined,
                    formEntityTypeId: undefined,
                    name: undefined,
                    categorySystemName: layoutModel
                        ? layoutModel.category
                        : undefined,
                    subCategorySystemName: undefined,
                    prefix: undefined,
                };
            })
        );
    }
}

import {Map} from 'immutable';
import {forIn} from 'lodash';
import {
    ConditionReadingService,
    PropertiesNode,
    VisibilityCondition,
} from '../../..';
import {
    getOptionalProperty,
    getRequiredProperty,
    JsonData,
} from '../../../interpreter-utils';
import {
    GridPropertiesModel,
    LayoutPropertiesModel,
} from '../../../model/properties';
import {PropertiesReader} from './properties-reader';
import {RowsPropertiesReader} from './rows-properties-reader';

export class GridPropertiesReader extends PropertiesReader {
    private readonly conditionReader: ConditionReadingService = new ConditionReadingService();

    constructor(properties: LayoutPropertiesModel) {
        super('grids', properties, new RowsPropertiesReader(properties));
    }

    protected readValue(value: object): PropertiesNode[] {
        if (!Array.isArray(value)) {
            throw new TypeError(`Property cells was not an array`);
        }
        return value.map(cellObject => {
            const visibilityRawData = getOptionalProperty(
                cellObject,
                'visibility',
                null
            );
            const propArray = [];
            forIn(cellObject, (val, key) => {
                propArray.push([key, val]);
            });
            const generalProps: Map<string, JsonData> = Map(propArray);
            const gridPropertiesModel = new GridPropertiesModel(
                getRequiredProperty(cellObject, 'id'),
                generalProps,
                new VisibilityCondition(
                    this.conditionReader.readCondition(visibilityRawData),
                    visibilityRawData
                )
            );
            this.properties.addGridProperties(gridPropertiesModel);
            return gridPropertiesModel;
        });
    }
}

import {InterpreterMetaField} from '../model/layout/kb-mock';
import {List} from 'immutable';

export class ReadingContext {

    private _currentPath: List<string> = List<string>().asMutable();

    constructor(
        public readonly fieldProvider: (field: string) => InterpreterMetaField
    ) {
    }

    get currentPath(): List<string> {
        return this._currentPath.asImmutable();
    }

    public addToPath(node: string) {
        this._currentPath.push(node);
    }
}

import {Map} from 'immutable';
import {JsonData} from '../../interpreter-utils';
import {PropertiesNode} from './properties-node';
import {VisibilityCondition} from './visibility-condition';

export class RowPropertiesModel extends PropertiesNode {

    public static createEmpty(id: string): RowPropertiesModel {
        return new RowPropertiesModel(id, Map(), Map());
    }

    constructor(id: string,
                public readonly style: Map<string, JsonData>,
                public readonly generalProperties: Map<string, JsonData>,
                public readonly visibility: VisibilityCondition = new VisibilityCondition(undefined, undefined)) {
        super(id);
    }

    public toJS(): object {
        return this.generalProperties.withMutations(mutable => {
            mutable.set('id', this.id);
            mutable.set('style', this.style);
            mutable.set('visibility', this.visibility.toJS());
        }).toJS();
    }
}

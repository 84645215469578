import {LayoutReader} from './layout-reader';
import {LayoutPropertiesModel} from '../../../model/properties/layout-properties.model';
import {MetaControl} from '../../../model/meta/meta-control';
import {GridModelReader} from './grid-model-reader';
import {PageLayoutModel} from '../../../model/layout/page-layout-model';
import {ReadingContext} from '../../reading-context';

export class PageLayoutModelReader extends LayoutReader<PageLayoutModel> {

    constructor(private readonly pageName: string,
                properties: LayoutPropertiesModel,
                controlResolver: (key: string) => MetaControl,
                context: ReadingContext) {
        super(pageName, properties, controlResolver, context,
              new GridModelReader(properties, controlResolver, context));
    }

    protected readValue(value: object): PageLayoutModel {
        return PageLayoutModel.fromJSON(value, this.pageName);
    }

}
